import React, { useState, useEffect } from 'react';
import main_logo from '../assets/img/logo.png';
import { TfiAlignJustify } from "react-icons/tfi";
import { TfiClose } from "react-icons/tfi";
export default function Header() {

    const [bgColor, setBgColor] = useState('bg-transparent');

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setBgColor('bg-white shadow-md');
        } else {
            setBgColor('bg-transparent');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [isToggled, setIsToggled] = useState(false);


    const showmenu = () => {
        setIsToggled(!isToggled);
    }

    return (

        <>
            <header className={`${bgColor} py-6 px-6 md:px-6 lg:px-0 sticky top-0 z-50 ${isToggled ? 'bg-color' : ''}`}>
                <section id="navbar" className='header-container '>
                    <div className="header-wrapper ">

                        <nav className={`flex justify-between items-start  lg:items-center lg:px-12 flex-col lg:flex-row  ${isToggled ? 'menu-background' : ''}`}>
                            <img src={main_logo} alt="Logo" className="header-logo max-w-full h-auto sm:h-[42px] md:h-[42px] lg:h-[79px]" />
                            <button className={`bg-[#546d87]  py-2 px-4 text-base text-white font-semibold hidden my-auto lg:block ${isToggled ? 'show-menu' : ''}`}>
                                Sign Up

                            </button>
                        </nav>
                    </div>

                    {isToggled ? (
                        <TfiClose className="absolute top-8 right-7 text-[1.25rem] text-black font-extrabold block lg:hidden cursor-pointer" onClick={showmenu} />
                    ) : (
                        <TfiAlignJustify className="absolute top-8 right-7 text-[1.25rem] text-black font-extrabold block lg:hidden cursor-pointer" onClick={showmenu} />
                    )}


                </section>
            </header>


        </>


    )
}
