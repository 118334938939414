import React from 'react'
import bg_img from "../assets/img/future-of-the-cyborgs.webp";
import marquee_bg_img from "../assets/img/marquee-bg.jpg";
import marquee_img from "../assets/img/marquee-img.png";

export default function Hero() {
  return (
    <>
      <section id="hero-area" class="hero-area mt-[-127px] h-[100%] relative">
        <div className="hero-container h-[100%]">
          <div className="hero-wrapper h-[100%]">

            <div className='h-[100%] z-[-999]'>
              <img src={bg_img} alt="background-image" className="hero-bg-img w-[100%] h-[105vh] pt-[35px] lg:pt-[0px] lg:h-[140vh] object-cover" />
            </div>

            <div className="absolute inset-0 z-10 bg-[#eef5fd] opacity-65"></div>

            <div className="hero-content-wrapper absolute top-32 z-[99] flex justify-center w-full">
              <div className="hero-content container mx-auto px-5 md:px-6 transition-all duration-300 pt-44 md:pt-44 lg:pt-48 pb-20 lg:pb-48">

                <div className="hero-content-sub max-w-[48rem]">
                  <h1 className='text-[36px] leading-[42px] font-extrabold md:text-[46px] md:leading-[54px] md:font-semibold xl:text-[72px] xl:leading-[82px] xl:font-normal'>Automate your futures trading with our advanced algorithm</h1>

                  <p className='transition-all duration-300 font-normal text-[18px] leading-[26px]  md:text-[18px] md:leading-[26px] lg:text-[24px] lg:leading-[32px] mt-4'>Achieve a 72% win rate with our automated trading solution—outperforming the industry average of 30-40% for professional traders.</p>

                  <div className="hero-btn flex md:inline-flex flex-col md:flex-row gap-4 w-full md:w-max  mt-8 lg:mt-8 z-50">
                    <button className='bg-[#293b4e] text-white font-medium text-[20px] py-[.75rem] px-[1.5rem]'>Access Platform</button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>



      <section id="hero-marquee" className="relative marquee-section">
        <div
          id="66f818e1c76491cd658353b7"
          className="flex flex-none relative items-center"
          style={{
            minHeight: 'calc(0px)',
            zIndex: -10,
            scrollMarginTop: 'calc(79px + 3rem)',
            paddingTop: '95px',
            paddingBottom: '0px',
          }}
        >
          <div className="absolute inset-0 z-10 pointer-events-none">
            <div
              className="absolute inset-0 z-10"
              style={{ backgroundColor: 'rgb(238, 245, 253)', opacity: 0.5 }}
            ></div>
            <div className="absolute inset-0 z-0 bg-white">
              <span
                style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  overflow: 'hidden',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                  position: 'absolute',
                  inset: 0,
                }}
              >
                <img
                  alt="marquee_background"
                  src={marquee_bg_img}
                  decoding="async"
                  style={{
                    position: 'absolute',
                    inset: 0,
                    boxSizing: 'border-box',
                    padding: 0,
                    border: 'none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </span>
            </div>
          </div>
          <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
            <div>
              <div className="flex gap-x-6 gap-y-12 flex-col">
                <div className="flex-1">
                  <div
                    className="flex overflow-hidden w-full"
                    style={{
                      WebkitMask: 'linear-gradient(90deg, transparent, white 5% 95%, transparent)',
                      mask: 'linear-gradient(90deg, transparent, white 5% 95%, transparent)',
                    }}
                  >
                    <div
                      className="flex flex-wrap items-center w-full justify-center gap-20 marquee-img"
                      style={{
                        minWidth: 'max-content',
                        transform: 'translate3d(-16.0409%, 0px, 0px)',
                      }}
                    >
                      <div className="relative flex flex-shrink-0 w-auto transition-all rounded-sm md:rounded-md lg:rounded-lg h-12 md:h-16 mr-20">
                        <img
                          className="h-full transition-all duration-300"
                          src={marquee_img}
                          alt="marquee_img"
                        />
                      </div>
                      <div className="relative flex flex-shrink-0 w-auto transition-all rounded-sm md:rounded-md lg:rounded-lg h-12 md:h-16 mr-20">
                        <img
                          className="h-full transition-all duration-300"
                          src={marquee_img}
                          alt="marquee_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute inset-0 z-10 bg-[#eef5fd] opacity-0"></div>

        </div>
      </section>
    </>

  )
}
