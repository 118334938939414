import React, { useEffect } from 'react';
import '../assets/css/Slider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import 'slick-carousel';

const ImageSlider = () => {
    useEffect(() => {
        $(".slider-wrapper").slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

    
        return () => {
            $(".slider-wrapper").slick('unslick');
        };
    }, []);

    const images = [
        require('../assets/img/carousel-demo.png'),
        require('../assets/img/carousel-demo2.png'),
        require('../assets/img/carousel-demo3.png'),
        require('../assets/img/carousel-demo4.png'),
        require('../assets/img/carousel-demo5.png'),
        require('../assets/img/carousel-demo6.png'),
    ];

    return (
        <div className="slider-main">
            <div className="slider-wrapper">
                {images.map((src, index) => (
                    <div className="slider-container" key={index}>
                        <img className="slide-img" alt="" title="" src={src} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
