import React from 'react';
import math_img from '../assets/img/maths.webp';
import risk_img from '../assets/img/risk.webp';
import percent_img from '../assets/img/percent.webp';
import recurring_img from '../assets/img/recurring.webp';

export default function ConnectTradeStation() {
  return (
    <>

      <section id="connect-trade" className='bg-[#eef5fd]'>
        <div class="md:max-w-3xl lg:max-w-7xl mx-auto p-3 md:p-6 lg:py-16">
          <div className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl my-5">
            <h1 className=" font-medium text-[30px] leading-[36px] md:text-[35px] md:leading-[42px] lg:text-[48px] lg:leading-[56px]">Connect your TradeStation account through our advanced API</h1>
          </div>

          <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
            <div class=" rounded-lg ">
              <img src={math_img} className=" rounded-none  md:rounded-lg w-full h-[42%] object-cover" />
              <h2 className=" font-medium text-[24px] leading-[32px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[40px] my-5">Let the Algorithm Trade and Protect Your Assets</h2>
              <p className="text-base text-[16px] leading-[24px] lg:text-[18px] lg:leading-[28px] ">
                Automated Trading and Protection: Let the platform trade on your behalf with built-in dynamic stops and targets.
              </p>
            </div>
            <div class=" rounded-lg ">
              <img src={risk_img} className="rounded-none  md:rounded-lg w-full h-[42%] object-cover" />
              <h2 className="font-medium text-[24px] leading-[32px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[40px] my-5">Select Your Risk Level</h2>
              <p className="text-base text-[16px] leading-[24px] lg:text-[18px] lg:leading-[28px]">
                Customize the platform to align with your personal risk tolerance. Choose between low, medium, or high-risk strategies, allowing you to control the level of risk you're willing to take while still benefiting from the algorithm’s precision.
              </p>
            </div>
            <div class=" rounded-lg">
              <img src={percent_img} className="rounded-none  md:rounded-lg w-full h-[42%] object-cover" />
              <h2 className="font-medium text-[24px] leading-[32px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[40px] my-5">Pay Only on Your Profits</h2>
              <p className="text-base text-[16px] leading-[24px] lg:text-[18px] lg:leading-[28px]">
                Our success is tied to yours—pay only when you profit. If the bot has a losing week, no fees are charged, and losses carry over to offset future gains.
              </p>
            </div>
          </div>
        </div>

      </section>



      <section id="referrals" className='bg-white flex justify-center'>
        <div className="py-14 w-[98%] md:w-11/12 lg:w-[82%] flex flex-col md:flex-row items-center gap-12">
          <div className="flex px-2 justify-center w-full md:w-1/2">
            <img src={recurring_img} className="m-5  w-full h-auto rounded-none md:rounded-xl" />
          </div>
          <div className="flex flex-col w-full md:w-1/2 px-5">
            <h1 className="text-3xl md:text-5xl my-5">Turn Referrals into Recurring Revenue</h1>
            <p className="text-base md:text-lg mb-5">
              Start earning a monthly income by simply recommending our platform to others. For every referral who joins, you'll receive a share of their profits—month after month, for as long as they stay. Your success grows as their success grows.
            </p>
            <button className="w-full sm:w-24 py-1 text-white text-lg hover:cursor-pointer bg-[#C8184C]">
              Contact
            </button>
          </div>
        </div>
      </section>




    </>
  )
}
