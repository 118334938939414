import React from 'react';
import trading_img from '../assets/img/trading.webp';
import ai_img from '../assets/img/AI.webp';
import premimum_img from '../assets/img/premimun.webp';
import { ImCheckmark } from "react-icons/im";

export default function Pricing() {
    return (
        <>
            <section id="platform" className='bg-white flex justify-center'>
                <div className="py-16 w-[82%]">
                    <div className="flex justify-center mb-14">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl">Platform Pricing</h1>
                    </div>

                    <div className="flex flex-wrap justify-center">

                        <div className="w-full  lg:w-1/3 border border-[#0006] px-4 py-4 md:px-4 md:py-5 mt-4 mb-4 rounded-none md:rounded-lg lg:rounded-none lg:border-r-0 lg:rounded-tl-lg lg:rounded-bl-lg">
                            <img src={trading_img} className="rounded-none md:rounded-xl w-full h-auto mb-6" />
                            <h2 className="text-2xl mb-10">Trading Signal Only</h2>
                            <h1 className="text-5xl">$139</h1>
                            <h4 className="text-lg mb-5">per month</h4>
                            <button className="w-full h-10 text-center text-white text-lg bg-[#C8184C]">More Info</button>

                            <p className="text-xl my-8">Receive essential trading signals only</p>
                            <div className="text-xl mb-4 flex">
                                <ImCheckmark className="mr-2" />On Screen Alerts
                            </div>
                            <div className="text-xl mb-4 flex">
                                <ImCheckmark className="mr-2" />Text Message Alert
                            </div>
                            <div className="text-xl flex">
                                <ImCheckmark className="mr-2" />Email Alerts
                            </div>
                        </div>

                        <div className="w-full lg:w-1/3 border-2 border-[#0006] px-4 py-4  md:px-4 md:py-9 rounded-none md:rounded-xl">
                            <img src={ai_img} className="rounded-none md:rounded-xl w-full h-auto mb-6" />
                            <span className="bg-black text-white px-3 py-1 rounded-2xl">Popular</span>
                            <h2 className="text-2xl mb-10 mt-3">Fully Automated API</h2>
                            <h1 className="text-5xl">$79</h1>
                            <h4 className="text-lg mb-5">per month</h4>
                            <button className="w-full h-10 text-center text-white text-lg bg-[#C8184C]">More Info</button>
                            <p className="text-xl my-8">Let us do all the work. Received alerts to monitor your profits</p>
                            <div className="text-xl mb-4 flex">
                                <ImCheckmark className="mr-2" />Fully Automated Trading Through API
                            </div>
                            <div className="text-xl flex">
                                <ImCheckmark className="mr-2" />Priority Support
                            </div>
                        </div>

                        <div className="w-full lg:w-1/3 border border-[#0006] px-4 py-4 md:px-4 md:py-5 mt-4 mb-4 rounded-none md:rounded-lg lg:border-l-0 lg:rounded-none lg:rounded-tr-lg lg:rounded-br-lg">
                            <img src={premimum_img} className="rounded-none md:rounded-xl w-full h-auto mb-6" />
                            <h2 className="text-2xl mb-10">Premium Accounts</h2>
                            <h1 className="text-5xl">$0</h1>
                            <h4 className="text-lg mb-5">per month</h4>
                            <button className="w-full h-10 text-center text-white text-lg bg-[#C8184C]">More Info</button>
                            <p className="text-xl my-8">Receive essential trading signals only</p>
                            <div className="text-xl mb-4 flex">
                                <ImCheckmark className="mr-2" />Fully Automated Trading Through API
                            </div>
                            <div className="text-xl flex">
                                <ImCheckmark className="mr-2" />Priority Support
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section id="matter">
                <div className="bg-[#53708B] comment-clip">
                    <div className="w-full pr-3 md:w-[85%] md:pl-8 mx-auto pt-7 pb-40 md:pt-10 md:pb-80 pl-5 lg:pr-16 xl:pr-44 ">
                        <h1 className="text-[24px] leading-[32px] font-medium md:text-[28px] md:leading-[36px] md:font-medium xl:text-[32px] xl:leading-[40px] xl:font-medium  text-white pt-2 pb-2 md:pt-10 md:pb-4 px-0 md:px-0">
                            AI Systemics has revolutionized my trading experience! Their bot is incredibly efficient and user-friendly. I'm seeing consistent gains and couldn't be happier. Thank you, AI Systemics!
                        </h1>
                        <h3 className="text-lg md:text-2xl text-white px-3 md:px-0">- Jason</h3>
                    </div>
                </div>
            </section>


        </>


    )
}
