import React from 'react';
import logo_black from '../assets/img/logo-black-red.png';

export default function FooterContents() {
    return (
        <section id="footer" className='bg-[#53708b] flex justify-center'>
            <div className=" text-white px-4 md:px-10 py-16 md:py-32  md:max-w-[90%] overflow-hidden">
                <img src={logo_black} alt="" className="logo-black w-full md:w-5/12 pb-10" />

                <p className="text-base md:text-lg mb-7">
                    The information contained on this website does not constitute investment advice. The risk of trading in securities markets, including futures and options, can be substantial. You must review and agree to our Disclaimers and Terms and Conditions before using this site.
                </p>
                <p className="text-base md:text-lg mb-7 ">
                    U.S. Government Required Disclaimer – Commodity Futures Trading Commission: Futures and options trading carries large potential rewards but also significant risks. You must be aware of these risks and be willing to accept them before investing in the futures and options markets. Do not trade with money you cannot afford to lose. This website is neither a solicitation nor an offer to buy or sell futures or options. No representation is being made that any account will or is likely to achieve profits or losses similar to those discussed on this website. The past performance of any trading system or methodology is not necessarily indicative of future results.
                </p>
                <p className="text-base md:text-lg mb-7 ">
                    Individual results may vary, and testimonials are not claimed to represent typical results.
                </p>
                <p className="text-base md:text-lg mb-7 ">
                    All testimonials are by real clients and may not reflect the typical user's experience. They are not intended to represent or guarantee that anyone will achieve the same or similar results.
                </p>
                <p className="text-base md:text-lg mb-7">
                    CFTC RULE 4.41 – HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN LIMITATIONS: Unlike an actual performance record, simulated results do not represent actual trading. Since the trades have not been executed, the results may have under-or-over compensated for the impact of certain market factors, such as lack of liquidity. Simulated trading programs are subject to the fact that they are designed with the benefit of hindsight. No representation is being made that any account will or is likely to achieve profit or losses similar to those shown.
                </p>
                <p className="text-base md:text-lg mb-7 ">
                    © 2024 AI Systemics LLC. ALL RIGHTS RESERVED. Reproduction without permission is prohibited.
                </p>

                <p className="text-base md:text-lg mb-2 ">AI Systemics LLC</p>
                <p className="text-base md:text-lg mb-7 ">USA</p>

                <p className="text-base md:text-lg mb-2 ">+1 833 461 3717</p>
                <p className="text-base md:text-lg mb-7 ">+1 218 718 2525</p>

                <p className="text-base md:text-lg mb-2 ">Support</p>
                <p className="text-base md:text-lg mb-7 ">Email: support@aisystemics.com</p>

                <div className="text-base md:text-lg mb-12 ">
                    <ul className="flex flex-wrap justify-center md:justify-start space-x-5">
                        <li className="hover:cursor-pointer">Privacy Policy</li>
                        <li className="hover:cursor-pointer">Terms of Services</li>
                        <li className="hover:cursor-pointer">Frequently Asked Questions</li>
                        <li className="hover:cursor-pointer">Affiliate</li>
                    </ul>
                </div>

                <span className="bg-white text-black py-2 px-4 md:px-8 text-base md:text-lg hover:cursor-pointer">
                    Support
                </span>
            </div>
        </section>

    )
}
